import React, { useEffect, useState } from "react";

import { Container } from "@material-ui/core";

import { useQuery } from "react-query";

import {
  Category,
  Product,
  Client,
  ProductModal,
  BlockedModal,
} from "../../components";

import { getCategories, getProducts } from "../../services";

import { useStore } from "../../store";

import {
  CategoryArray,
  ProductArray,
  Product as ProductInterface,
  Sector as SectorType,
} from "../../types";

import useStyles from "./styles";

import { getFirebaseRealTimeDatabase } from "../../services/firebaseRealTimeDatabase";
import { useGetEntity, useGetSectorsActive } from "../../api/orders/hooks";
import { Sector } from "../../components/Sector";

export const VisitorPurchase: React.FC = () => {
  const { userManager, userTotem } = useStore();

  const [openProductModal, setOpenProductModal] = useState<boolean>(false);
  const [openBlockedModal, setOpenBlockedModal] = useState<boolean>(false);

  const [onShowSectors, setOnShowSectors] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("0");
  const [categories, setCategories] = useState<any>([]);
  const [message, setMessage] = useState<string>("");
  const [selectedProduct, setSelectedProduct] =
    useState<ProductInterface | null>(null);
  const [sectorsList, setSectorsList] = useState<any[]>([]);
  const [sectorId, setSectorId] = useState("");
  const [quantitySectors, setQuantitySectors] = useState(0);

  const Styles = useStyles({ onShowSectors, quantitySectors });

  const handleClickInProduct = (product: ProductInterface) => {
    setSelectedProduct(product);

    setOpenProductModal(true);
  };

  const closeModal = () => {
    setOpenProductModal(false);

    setSelectedProduct(null);
  };

  const isBlockedProduct = (price: number): boolean =>
    price / 100 > userTotem.wallet.periodLimit - userTotem.wallet.spent &&
    !!userTotem.id;

  const { data: entity } = useGetEntity(userManager.entity, true);
  const { data: sectors } = useGetSectorsActive(userManager.entity, true);
  const { data: categoryData, isLoading: loadingCategory } = useQuery<
    CategoryArray,
    Error
  >("categoriesData", () => getCategories(userManager.entity));
  const {
    data: productsData,
    isLoading: loadingProducts,
    refetch: getProductsQuery,
  } = useQuery<ProductArray, Error>("productsData", () =>
    getProducts(userManager.entity, userTotem.id));

  const filterProducts = () => {
    const products =
      productsData?.filter((product) => !product.isvariablePrice) ?? [];

    const filterByCategory = (product: any) =>
      product.productCategory.id === selectedCategory;
    const filterBySector = (product: any) => product.segment?.id === sectorId;
    const filterByHighlight = (product: any) => product.isHighlighted;
    const filterByNoSegment = (product: any) => !product.segment;

    if (onShowSectors) {
      if (selectedCategory !== "0") {
        if (isSectorSelectedOthers(sectorId)) {
          return products.filter(
            (product) => filterByNoSegment(product) && filterByCategory(product)
          );
        }
        return products.filter(
          (product) => filterByCategory(product) && filterBySector(product)
        );
      }

      if (isSectorSelectedOthers(sectorId)) {
        return products.filter(
          (product) => filterByHighlight(product) && filterByNoSegment(product)
        );
      }
      return products.filter(
        (product) => filterByHighlight(product) && filterBySector(product)
      );
    }

    if (selectedCategory !== "0") {
      return products.filter(filterByCategory);
    }

    return products.filter(filterByHighlight);
  };

  const isSectorSelectedOthers = (id: string) => id === "others";

  const filterProductsSectors = (id?: string) => {
    if (id && productsData && categoryData) {
      setSelectedCategory("0");
      let filteredProducts: any[] = [];
      if (isSectorSelectedOthers(id)) {
        filteredProducts = productsData.filter((prod: any) => !prod.segment);
      } else {
        filteredProducts = productsData.filter(
          (prod: any) => prod.segment?.id === id && !prod.isvariablePrice
        );
      }

      const uniqueCategoryIds = new Set(
        filteredProducts.map((p: any) => p.productCategory.id)
      );
      const uniqueCategories = categoryData.filter((cat: any) =>
        uniqueCategoryIds.has(cat.id));
      setCategories(uniqueCategories);
    }
    return [];
  };

  const handleClickProduct = (product: ProductInterface) => {
    if (!product.isLocked) {
      if (isBlockedProduct(product.price)) {
        setOpenBlockedModal(true);
      } else if (product.quantity === 0) {
        setMessage("Esse item não tem mais estoque");
        setOpenBlockedModal(true);
      } else {
        handleClickInProduct(product);
      }
    }
  };

  const close = () => {
    setOpenBlockedModal(false);
    setMessage("");
  };

  const refresh = () => {
    if (userTotem.id) {
      getProductsQuery();
    }
  };
  const onSectorsList = () => {
    const secList = sectors.map((s: SectorType, i: number) => ({
      ...s,
      selected: i === 0,
    }));
    secList.sort((a: SectorType, b: SectorType) =>
      a.name.localeCompare(b.name));
    secList.push({
      id: "others",
      name: "Outros",
      isActive: true,
      selected: false,
      products: [],
    });
    setSectorsList(secList);
  };

  useEffect(() => {
    if (sectors?.length > 0 && onShowSectors) {
      onSectorsList();
      setQuantitySectors(sectors.length);
      setSectorId(sectors.sort((a: SectorType, b: SectorType) => a.name.localeCompare(b.name))[0].id);
      filterProductsSectors(sectors[0].id);
    }
  }, [sectors, categoryData, productsData, onShowSectors]);

  useEffect(() => {
    if (categoryData && !onShowSectors) {
      setCategories(categoryData);
    }
  }, [categoryData, onShowSectors]);

  useEffect(() => {
    if (entity?.isSectorsOnThePurchaseTotem && sectors?.length > 0) {
      setOnShowSectors(true);
    }
  }, [entity, sectors]);

  useEffect(() => {
    if (sectors?.length > 0 && onShowSectors) {
      onSectorsList();
      setQuantitySectors(sectors.length);
      setSectorId(sectors[0].id);
      filterProductsSectors(sectors[0].id);
    }
  }, [sectors, categoryData, productsData, onShowSectors]);

  useEffect(() => {
    if (categoryData && !onShowSectors) {
      setCategories(categoryData);
    }
  }, [categoryData, onShowSectors]);

  useEffect(() => {
    getFirebaseRealTimeDatabase(userManager.entity, refresh);
  }, []);

  return (
    <Container className={Styles.container} maxWidth={false}>
      {onShowSectors && (
        <>
          <div className={Styles.sectors}>
            <div className={Styles.sectorsContainer}>
              {sectorsList?.map((sec) => (
                <Sector
                  {...sec}
                  key={sec.id}
                  name={sec.name}
                  onClick={(id) => filterProductsSectors(id) && setSectorId(id)}
                  selected={sec.id === sectorId}
                />
              ))}
            </div>
            <div className={Styles.divider} />
          </div>
        </>
      )}
      {!loadingCategory && categories.length > 0 && (
        <div className={Styles.categories}>
          <Category
            id="0"
            picture=""
            name="Destaques"
            onClick={(id) => setSelectedCategory(id)}
            selected={selectedCategory === "0"}
          />

          {categories!.map((category: any) => (
            <Category
              key={category.id}
              {...category}
              onClick={(id) => setSelectedCategory(id)}
              selected={category.id === selectedCategory}
            />
          ))}
        </div>
      )}

      <Client visitor onShowSectors={onShowSectors} />

      <Container className={Styles.content} maxWidth={false}>
        {!loadingProducts && (
          <div className={Styles.products}>
            {filterProducts().map((product: any) => (
              <Product
                key={product.id}
                {...product}
                isLocked={
                  isBlockedProduct(product.price) ||
                  product.isLocked ||
                  product.quantity === 0
                }
                onClick={() => handleClickProduct(product)}
              />
            ))}
          </div>
        )}
      </Container>

      <BlockedModal message={message} open={openBlockedModal} onClose={close} />

      {selectedProduct && (
        <ProductModal
          open={openProductModal}
          onClose={closeModal}
          product={selectedProduct}
        />
      )}
    </Container>
  );
};
